<template>
    <div class="todos-box">
        <div v-if="pageTotal == 0" style="font-size:14px;color:#999;">
            暂无数据
        </div>
        <section class="todos-list">
            <rowsModule :value="item" v-for="(item,index) in lists" :key="index" :user-type="userInfo.info.user_type"/> 
        </section>
        <pagination
            :pageSize="pageSize"
            :currentPage="curPage"
            :hide-on-single-page="true"
            @current-change="currentPageChange"
            :pageTotal = "pageTotal"
            v-if="pageTotal > 0 "
        ></pagination>
    </div>
</template>
<script>
import { mapState } from "vuex";
import Pagination from "@/components/common/Pagination.vue";
import rowsModule from "./module/rows.vue";
export default {
    name: "todosStatus",
    components: {
        Pagination, //分页,
        rowsModule
    },
    data() {
        return {
            status: 0, // 代表事项的状态，0全部，1报名中，2，评选中，3，执行中，4，已完成，5调解中，6，已结束
            curPage: 1, // 当前分页
            pageSize: 10, // 每页显示条数
            pageTotal: 0, // 总页数
            resDatas: {}, // 请求返回的数据
            lists: []
        };
    },
    computed: {
        ...mapState(["userInfo"])
    },
    methods: {
        currentPageChange(currentPage) {
            // 分页改变时
            this.curPage = currentPage;
            this.getDatas();
        },
        getDatas() {
            var _this = this;
            _this.post(
                "/task/task/matters",
                {
                    page: _this.curPage,
                    limit: _this.pageSize,
                    status: _this.status,
                    task_id:this.$route.query.task_id?this.$route.query.task_id:0
                },
                res => {
                    if (200 == res.code) {
                        _this.resDatas = res;
                        _this.lists = res.data.lists; 
                        _this.curPage = parseInt(res.data.page); // 当前分页
                        _this.pageSize = parseInt(res.data.limit); // 每页显示条数
                        _this.pageTotal = parseInt(res.data.total) > 0 ? parseInt(res.data.total) : 0; // 总页数 
                    }
                }
            );
        },
        appealBtn() {
            //调解
            this.$router.push("/appealform");
        }
    },
    created() {
        this.getDatas();
    },
    watch: {
        $route: {
            handler: function(val, oldVal) {
                switch(val.params.id){
                    case 'all': this.status = 0; break;
                    case 'enrolled': this.status = 1; break;
                    case 'sprogress': this.status = 2; break;
                    case 'progress': this.status = 3; break;
                    case 'completed': this.status = 4; break;
                    case 'apply': this.status = 5; break;
                    case 'end': this.status = 6; break;
                }
                this.curPage = 1;
                this.getDatas();
            },
            // 深度观察监听
            deep: true
        }
    }
};
</script>
<style lang="less" scoped>
@import "../../../styles/todos.less";
</style>