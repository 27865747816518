<template>
    <el-row
        :gutter="24"
        class="todos-item"
    >
        <el-col :span="2">
            <div class="grid-content witicon-box">
                <span class="pic-box">
                    <img
                        v-for="(items,key) in statusObj"
                        :key="key"
                        :src="items.img"
                        v-if="value.status == items.value"
                    />
                </span>
            </div>
        </el-col>
        <el-col :span="15">
            <div class="grid-content todo-msg">
                <h4 class="todo-tit">
                    <span class="todo-tit-txt">{{value.task_name}}...</span>
                    <span
                        class="td-statu clr-green"
                        v-for="(items,key) in statusObj"
                        v-if="value.status == items.value"
                    >{{items.lable}}</span>
                </h4>
                <ul
                    class="msg-time"
                    v-html="getStatus(value._status.status,value._status._msg,value._status._showTime)"
                ></ul>
            </div>
        </el-col>
        <el-col :span="7">
            <div class="grid-content bg-purple operation-box">
                <a
                    @click="goDetail1(value.id)"
                    href="javascript:void(0)"
                    class="oper-lik"
                    v-if="value._status._oneButton != ''"
                >{{value._status._oneButton}}</a>
                <a
                    @click="goDetail2(value.id)"
                    href="javascript:void(0)"
                    class="oper-lik"
                >{{value._status._twoButton}}</a>
            </div>
        </el-col>
    </el-row>
</template>
<script>
export default {
    props: {
        value: {
            // type: Object,
            // // 对象或数组默认值必须从一个工厂函数获取
            // default: function() {
            //     return {};
            // }
        },
        userType: {
            type: Number,
            default: 1
        }
    },
    data() {
        return {
            statusObj: [
                {
                    lable: "报名中",
                    value: 1,
                    img: this.$Img.baom
                },
                {
                    lable: "评选中",
                    value: 2,
                    img: this.$Img.pingx
                },
                {
                    lable: "执行中",
                    value: 3,
                    img: this.$Img.doing
                },
                {
                    lable: "已完成",
                    value: 4,
                    img: this.$Img.over
                },
                {
                    lable: "调解中",
                    value: 5,
                    img:
                        this.$Img.daiban_shensuzhong
                },
                {
                    lable: "已结束",
                    desc: "新报名工程极客",
                    value: 6,
                    img:
                        this.$Img.daiban_yizhongzhi
                }
            ]
        };
    },
    methods: {
        getStatus(status, msg, msg2) {
            let str = "";
            let userType = this.userType;
            let template = s => {
                return '<li class="first">' + s + "</li>";
            };
            let template1 = (s, m) => {
                return (
                    '<li class="first">' +
                    s +
                    '</li><li><i class="clr-red">' +
                    m +
                    "</i></li>"
                );
            };
            let template2 = (s, m, n, c = "clr-green") => {
                return (
                    "<li>" +
                    s +
                    '<i class="' +
                    c +
                    '">' +
                    m +
                    "</i>" +
                    n +
                    "</li>"
                );
            };
            let template3 = s => {
                return "<li>" + s + "</li>";
            };
            let template4 = (s, m, n) => {
                return (
                    ' <li class="first">' +
                    s +
                    '<i class="clr-red">' +
                    m +
                    '</i></li><li>'
                    // '</i></li><li><i class="clr-red">' +
                    // n +
                    // "</i>后截止</li>"
                );
            };
            switch (status) {
                case 1:
                    str =
                        userType == 1
                            ? template(
                                "您报名的项目还未上传附件,请在报名截止时间内上传附件"
                            )
                            : template4("", msg, msg2);
                    break;
                case 2:
                    str =
                        userType == 1
                            ? template(msg)
                            : template("已报名已处理未邀请");
                    break;
                case 3:
                    str = template("已报名已处理未邀请");
                    break;
                case 4:
                    str = template("已邀请待确认");
                    break;
                case 5:
                    str =
                        userType == 1
                            ? template1(
                                "您被选中为项目合作者，请在12小时内确认接受项目",
                                msg
                            )
                            : template("已确认邀请，请在24小时内托管酬金");
                    break;
                case 6:
                    str =
                        userType == 1
                            ? template("已邀请已拒绝")
                            : template2(
                                "对方未在规定时间内确认接受项目，请在",
                                "6天23小时内",
                                "重新完成选择",
                                "clr-red"
                            );
                    break;
                case 7:
                    str =
                        userType == 1
                            ? template1(
                                "您被选中为项目合作者，请在12小时内确认接受项目",
                                msg
                            )
                            : template("成果未交付或支付成果不通过");
                    break;
                case 8:
                    str = template("可确认成果交付");
                    break;
                case 9:
                    str = template("款项未支付");
                    break;
                case 10:
                    str = template2(
                        "项目已结束了，",
                        "去说说对方是否让您满意吧~",
                        "",
                        "clr-green"
                    );
                    break;
                case 11:
                    str = template3("已评价");
                    break;
                case 12:
                    str = template("已向平台发起调解");
                    break;
                case 13:
                    str = template("平台处理调解");
                    break;
                case 14:
                    str = template("已结束");
                    break;
                case 15:
                    str = template("已结束重选极客");
                    break;
            }
            return str;
        },
        goDetail1(id) {
            this.$router.push("/user/todos/detail/" + id);
        },
        goDetail2(id) {
            this.$router.push("/user/todos/detail/" + id);
        }
    }
};
</script>
<style lang="less" scoped>
@import "../../../../styles/todos.less";
</style>